@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src:    url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
            url('./assets/fonts/Montserrat-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src:    url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
            url('./assets/fonts/Montserrat-Bold.woff') format('woff');
    font-display: swap;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: 'Montserrat', sans-serif;
    transition: all 0.04s ease-in;
}

:root {
    --background: hsl(240, 27.4%, 94%);
    --inverse-text: hsl(240, 27.4%, 94%);
    --primary:  rgba(16, 16, 16, 1);
    --text: rgba(16, 16, 16, 1);
    --secondary: hsl(240, 27.4%, 60%);
    --secondary-light:  hsl(240, 27.4%, 85%);
    --action: hsl(32.471, 59.3%, 80%);
    --active: hsl(32.471, 59.3%, 80%);
    --font-size: 1.25rem;
    --section-heading: hsl(240, 27.4%, 50%);
}

[data-theme="dark"] {
    --background:rgba(16, 16, 16, .97);
    --inverse-text:  rgba(16, 16, 16, 1);
    --primary:  rgba(16, 16, 16, 1);
    --text:  hsl(240, 27.4%, 94%);
    --secondary: hsl(240, 27.4%, 60%);
    --secondary-light:  hsl(240, 27.4%, 18%);
    --action: hsl(32.471, 59.3%, 60%);
    --active: hsl(32.471, 59.3%, 60%);
    --font-size: 1.25rem;
    --section-heading: hsl(240, 27.4%, 60%);
}

html {
    min-width: 280px;
    background-color: rgba(16, 16, 16, .97);
}

@media(max-width: 400px) {
    :root {
        font-size: 1rem;
    }
}