.toggle {
    display: flex;
    align-items: center;
    margin: 16px;
}

.toggle input {
    display: none;
}

.slider {
    position: relative;
    display: inline-block;
    width: 63px;
    height: 34px;
    background-color: var(--inverse-text);
    border-radius: 34px;
    transition: background-color 0.3s;
    margin: 5px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 3px;
    background-color: var(--action);
    border: 4px solid var(--text);
    border-radius: 50%;
    transition: transform 0.4s;
}

input:checked + .slider {
    background-color: var(--secondary);
}

input:checked + .slider:before {
    transform: translateX(24px);
}
