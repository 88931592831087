@import '../../App.css';

header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 280px;
}

.navbar {
    background-color: var(--primary);
    height: 10vh;
    min-height: 65px;
    width: 100%;
    position: sticky;
    top: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    z-index: 9999; 
}

.nav-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    opacity: 1;
    z-index: 9999; 
}

.name, .nav-link {
    color: #f4f1f6;
    text-decoration: none;
}

h1 {
    font-size: 1.5rem;
}

.nav-link {
    margin: 10px;
}

.nav-link:hover {
    color: var(--active);
}

.active {
    font-weight: bold;
    color: var(--active);
}



@media(max-width:895px) {
    .name {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}   

@media (max-width: 475px) {
    h1, .nav-menu {
        font-size: 1.4rem;
    }
}

@media (max-width: 355px) {
    h1, .nav-menu {
        font-size: 1rem;
    }
}

@media (max-width: 300px) {
    .name {
        justify-content: left;
    }
}