.timeline-container {
    margin: auto;
    margin: 30px 0 200px 0;
    height: 100%;
}

.timeline-event {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.timeline-event::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: var(--text);
    left: calc(50% - 2px)
}

.timeline-item {
    margin-bottom: 40px;
    width: 100%;
    position: relative;
}

.timeline-item:last-child {
    margin-bottom: 0;
}

.timeline-item:nth-child(odd) {
    padding-right: calc(50% + 40px);
    text-align: right;
}

.timeline-item:nth-child(even) {
    padding-left: calc(50% + 40px);
}

.timeline-dot {
    height: 60px;
    width: 60px;
    background-color: var(--secondary);
    position: absolute;
    left: 50%; 
    transform: translate(-50%);
    border-radius: 50%;
    border: 4px solid var(--text);
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeline-content {
    background-color: var(--secondary-light);
    padding: 30px;
}

.timeline-date {
    font-weight: bold;
    background-color: var(--text);
    color: var(--inverse-text);
    border-radius: 20px;
    font-size: 1rem;
    padding: 5px 10px 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

.timeline-content h4 {
    font-size: 1.25rem;
}

.timeline-content h5, p {
    font-size: 1rem;
}

.timeline-content h6 {
    padding-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
}

.italics {
    font-style: italic;
}

.timeline-dot:last-of-type {
    background-color: var(--action);
}

.timeline-dot:last-of-type:hover {
    filter: brightness(115%);
}

@media(max-width: 800px) {
    .timeline-item:nth-child(odd) {
        padding-right: 0;
        text-align: left;
    }

    .timeline-item:nth-child(odd),
    .timeline-item:nth-child(even) {
        padding-left: 40px;
    }

    .timeline-event::before {
        left: 25px;
    }

    .timeline-dot {
        left: 26px;
    }

    .timeline-date, .timeline-content {
        margin-left: 30px;
    }

    .timeline-content {
        margin-bottom: 50px;
    }

    .resume-button-container {
        justify-content: left;
    }
}

@media(max-width: 400px) {
    .timeline-dot:last-of-type:hover {
        filter: none;
    }
}

@media(max-width: 375px) {
    .timeline-event::before, .timeline-dot svg, .timeline-dot {
        display: none;
    }

    .timeline-date, .timeline-content {
        margin-left: 0;
    }

    .timeline-item:nth-child(odd),
    .timeline-item:nth-child(even) {
        padding-left: 0;
    }

    .timeline-item {
        margin-bottom: 0;
    }

    .timeline-item .timeline-content {
        padding: 15px;
        margin: 0 0 30px 0;
        width: 100%;
    }

    .download-button {
        width: 100%;
        margin: 10px 0 10px 0;
    }

    .view-button {
        display: none;
    }

    .resume-button-container {
    margin: 0;
    justify-content: center;
    }

    .timeline-content {
        margin-bottom: 20px;
    }

    .timeline-container {
        margin-bottom: 30px;
    }
}