@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.scroll-container {
    background-color: var(--background);
    color: var(--text);
    height: 90vh;
    width: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    font-size: 1rem;
    scroll-padding: 5vh 0;
    z-index: 1;
}

section {
    min-height: 100%;
    max-height: fit-content;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 280px;
}

.content-container {
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 1200px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 1; 
}

.content-text {
    padding: 20px 0 20px 0;
    font-size: 1.25rem;
}

.content-text h2 {
    text-transform: uppercase;
    font-weight: 800;
    color: var(--section-heading);
    font-size: 1rem;
    margin-bottom: 10px;
}

.content-text h3 {
    font-weight: bold;
}

/* universal button hover styles */

button {
    transition: filter 0.3s ease, transform 0.3s ease;
    border: 2px solid  rgba(16, 16, 16, 1);
}

button:hover {
    filter: brightness(115%);
}

/* custom-scroll-bar */

::-webkit-scrollbar {
    width: 1.75em;
}

::-webkit-scrollbar-track {
    background: var(--secondary-light);
}

::-webkit-scrollbar-thumb {
    background: var(--secondary);
    /* border: 2px solid var(--text); */
}

@media (max-width: 1000px) {
    .content-container {
        width: 95%;
    }
}

@media (max-width: 500px) {
    ::-webkit-scrollbar {
        width: 1em;
    }
}

@media (max-width: 400px) {
    ::-webkit-scrollbar {
        display: none;
    }

    button:hover {
        filter: none;
    }
}