.custom-iframe {
    width: 100%;
    height: 100%;
    border: none; 
}

.custom-overlay {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.download-button, .view-button {
    height: 72px;
    padding: 0 15px 0 15px;
    font-size: 1rem;
    font-weight: bold;
    color:  rgba(16, 16, 16, 1);
    margin: 15px 0 10px 15px;
}

.download-button, .view-button {
    width: 200px;
}

.download-button {
    background-color: var(--action);
}

.download-button a {
    text-decoration: none;
    color: rgba(16, 16, 16, 1);
}

.view-button {
    background-color: var(--secondary);
}

.close-button {
    background-color: var(--action);
    margin-bottom: 20px;
    text-align: right;
    display: block;
    margin-left: auto;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color:  rgba(16, 16, 16, 1);
}

.resume-button-container {
    width: 100%;
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    margin: 20px 0 20px 0;
}

.resume-button-container button div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.resume-button-container button span {
    line-height: 1;
}

.resume-button-container {
    display: flex;
    justify-content: right;
}

.modal-button-container button div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-button-container button span {
    line-height: 1;
}

@media(max-width: 375px) {
    #resume .content-text {
        padding-bottom: 0;
    }
}