.dark-mode svg {
    stroke: #f4f1f6;
    width: 30px;
    height: auto;
}

#about h5 svg {
    stroke: var(--text);
}

.socials-container svg {
    fill: var(--text);
    width: 40px;
    height: auto;
    margin-right: 20px;
    margin-bottom: 10px;
}

.link-buttons-container svg {
    fill:  rgba(16, 16, 16, 1);
    width: 40px;
    min-width: 40px;
    height: auto;
    padding-right: 2px;
}

.view-button svg, .close-button svg, .contact-close-button svg {
    fill:  rgba(16, 16, 16, 1);
    width: 40px;
    min-width: 40px;
    height: auto;
}

.view-button svg {
    padding-right: 2px;
}

.timeline-dot svg, .contact-dot svg {
    fill:  rgba(16, 16, 16, 1);
    width: 40px;
    height: 40px;
}

.download-button svg {
    display: flex;
    align-items: center;
    justify-content: center;
    stroke: rgba(16, 16, 16, 1);
    width: 40px;
    min-width: 40px;
    padding-right: 2px;
    fill:none;
}

/* contact */

.img-close-button svg, 
#contact svg {
    fill:  rgba(16, 16, 16, 1);
    width: 40px;
    min-width: 40px;
    height: auto;
    padding-right: 2px;
}

/* hovers */

.socials-container svg:hover, 
footer svg:hover {
    fill: var(--active)
}

/* footer */
footer svg {
    margin-top: 10px;
    fill: #f4f1f6;
}

.footer-socials-container svg {
    height: auto;
    margin-right: 20px;
    margin-bottom: 20px;
}

@media (max-width: 450px) {
    .socials-container svg,
    .footer-socials-container svg {
        width: 30px;
        height: auto;
        margin: 10px;
    }
}

@media(max-width: 400px) {
    .socials-container svg:hover, 
    footer svg:hover {
    fill: var(--text)
    }
}