.projects {
    margin-bottom: 200px;
}

.project-card {
    background-color: var(--secondary-light);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    padding: 30px;
}

/* project info text (LEFT SIDE) */
.project-info {
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}

.project-text h4 {
    font-size: 2rem;
    padding-bottom: 5px;
}

.project-text h5 {
    font-size: 1rem;
    padding-bottom: 8px;
    text-decoration: none;
}

.project-text h5 a {
    text-decoration: none;
    color: var(--text);
}

.project-text p {
    font-size: 1.1rem;
    padding-bottom: 5px;
}

/* tags */
.demo-tag {
    background-color: var(--action);
    color:  rgba(16, 16, 16, 1);
    padding: 5px 10px 5px 10px;
    margin: 10px 0 10px 0;
    border-radius: 20px;
    font-size: 1rem;
    text-align: center;
    width: fit-content;
}

.bold {
    font-weight: 600;
}

.tag-container {
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.tags {
    background-color: var(--text);
    color: var(--inverse-text);
    padding: 5px 10px 5px 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 20px;
    font-size: 1rem;
}

/* buttons */
.link-buttons-container {
    width: 100%;
    display: flex;
    align-items: flex-start; 
    justify-content: space-between;
}

.link-buttons-container button {
    padding: 15px;
    font-size: 1rem;
    font-weight: bold;
    color:  rgba(16, 16, 16, 1);
}   

.link-buttons-container button div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-buttons-container button span {
    line-height: 1;
}

.deploy-link {
    background-color: var(--action);
    width: 32%;
}

.github-link, .vscode-link {
    width: 32%;
}

.github-link, .vscode-link, .button-color {
    background-color: var(--secondary);
}

/* project img (RIGHT SIDE) */
.project-img-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center; 
}

.project-img {
    max-width: 95%;
    aspect-ratio: 1503 / 950 ;
    margin: 0 auto;
    padding: 20px 0 20px 0;
}

@media (max-width: 1000px) {
    .project-card {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
        padding: 20px;
    }

    .project-info, .project-img-container {
        width: 100%;
        justify-content: center;
    }

    
    .project-img {
        margin: 0;
        padding: 0;
        max-width: 100%;
    }

    .project-url {
        display: none;
    }

    .demo-tag {
        margin: 10px auto;
    }

    .link-buttons-container { 
        margin-top: 20px;
    }
}

@media(max-width: 700px) {
    .link-buttons-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center; 
    }

    .deploy-link {
        width: 100%;
    }
    
    .github-link, .vscode-link {
        width: 49%;
        margin-top: 10px;
    }

    .demo-tag {
        width: 100%;
    }
}

@media (max-width: 375px) {
    .deploy-link, .github-link, .vscode-link {
        width: 100%;
    }

    .project-info {
        height: fit-content;
        width: fit-content;
        padding: 0;
        margin: 10px;
    }

    .link-buttons-container {
        margin: 10px 0 10px 0;
        height: fit-content;
        width: fit-content;
    }

    .projects {
        margin-bottom: 30px;
    }
}