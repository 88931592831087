.card-group { 
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(30vmin, 1fr));
    position: relative; 
    margin: 50px;
}

.card {
    width: 30vmin;
    position: absolute;
    transition: transform 800ms cubic-bezier(.05, .43, .25, .95);
    border: 7px solid var(--text);
    background-color:  var(--text);
    border-radius: 10px;
}

.big-card {
    width: 30vmin;
}

.little-card {
    width: 18vmin;
    aspect-ratio: 1 / 1;
}

.little-card:nth-child(1) {
    background-image: url(../images/about-img/avif/skate-bowl.avif);
    aspect-ratio: 1 / 1;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
}

.big-card:nth-child(2) {
    background-image: url(../images/about-img/avif/bmx-mag.avif);
    aspect-ratio: 8 / 10;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
    transform: translateX(-10%) rotate(-7deg);
}

.little-card:nth-child(3) {
    background-image: url(../images/about-img/avif/midjourney.avif);
    aspect-ratio: 1 / 1;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
}

.big-card:nth-child(4) {
    background-image: url(../images/about-img/avif/skate.avif);
    aspect-ratio: 5 / 7;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
    transform:  translateX(10%)rotate(7deg);
}

.little-card:nth-child(5) {
    background-image: url(../images/about-img/avif/kitties.avif);
    aspect-ratio: 1 / 1;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
}

.big-card:nth-child(6) {
    width: 35vmin;
    background-image: url(../images/about-img/avif/Elizabeth.avif);
    aspect-ratio: 1 / 1;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
    transform: rotate(4deg);
}

.card-group:hover > .big-card:nth-child(2) {
    transform: translate(-60%, 5%) rotate(-20deg);
}

.card-group:hover > .big-card:nth-child(4) {
    transform: translate(65%, 5%) rotate(20deg);
}

.card-group:hover > .big-card:nth-child(6) {
    transform: translate(0, -15%) rotate(0deg);
}

.card-group:hover > .little-card:nth-child(1) {
    transform: translate(-5%, 135%) rotate(5deg);
}

.card-group:hover > .little-card:nth-child(3) {
    transform: translate(-130%, -120%) rotate(6deg);
}

.card-group:hover > .little-card:nth-child(5) {
    transform: translate(130%, -120%) rotate(-15deg);
}

/* image modals */

.image-modal {
    border: none;
    background-color: rgba(16, 16, 16, 1);
    border: 2px solid hsl(240, 27.4%, 94%);
    padding: 20px;
    width: 80%;
    max-width: 800px;
    height: auto;
}

.modal-image {
    width: 85%;
    max-width: 500px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.img-close-button {
    background-color: var(--action);
    margin: 20px;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
    display: block;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: rgba(16, 16, 16, 1);
}

.modal-caption {
    color: hsl(240, 27.4%, 94%);
    width: 60%;
    text-align: center;
    margin: 0 auto;
    padding: 5px 10px 5px 10px;
    margin-top: 10px;
    border-radius: 20px;
    font-size: 1rem;
}

.initiate-hover > .big-card:nth-child(2) {
    transform: translate(-60%, 5%) rotate(-20deg);
}

.initiate-hover > .big-card:nth-child(4) {
    transform: translate(65%, 5%) rotate(20deg);
}

.initiate-hover > .big-card:nth-child(6) {
    transform: translate(0, -15%) rotate(0deg);
}

.initiate-hover > .little-card:nth-child(1) {
    transform: translate(-5%, 135%) rotate(5deg);
}

.initiate-hover > .little-card:nth-child(3) {
    transform: translate(-130%, -120%) rotate(6deg);
}

.initiate-hover > .little-card:nth-child(5) {
    transform: translate(130%, -120%) rotate(-15deg);
}   

@media(max-width: 900px) {
    .modal-caption {
        width: 85%;
    }
}

@media(max-width: 1125px) {
    .card {
        border: 5px solid var(--text);
    }
}

@media(max-width: 600px) {
    .card {
        border: 3px solid var(--text);
    }
}