footer {
    height: 20%;

}

#footer {
    background-color: var(--primary);
    width: 100%; 
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    color: #f4f1f6;
}

.footer-email {
    padding-bottom: 20px;
}

.copyright, .footer-email {
    font-size: .9rem;
}

.source-code a {
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(16, 16, 16, 1);
    font-weight: 600;
    background-color: #f4f1f6;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    font-size: .9rem;
    margin: 10px;
}

.source-code {
    margin: 10px;
    margin-top: 10px;
}

.source-code a:hover {
    background-color: var(--active);
}

@media(max-width: 400px) {
    .source-code a:hover {
        background-color: var(--text);
    }
}

@media(max-width: 375px) {
    footer h3 {
        font-size: 1rem;
    }
}