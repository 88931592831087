@import '../../App.css';

.hamburger {
    display: none;
    cursor: pointer;
}

.hamburger-bar {
    display: flex;
    width: 2rem;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    background-color: #f4f1f6;
}

@media(max-width:895px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .name {
        flex-grow: 1;
        text-align: center;
    }
    
    .hamburger {
        display: flex;
        align-items: center;
    }
    
    .hamburger-container {
        display: block;
    }

    .hamburger.active .hamburger-bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .hamburger-bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .hamburger-bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
    
    .nav-menu {
        position: fixed;
        right: -100%;
        top: 65px;
        gap: 0;
        flex-direction: column;
        background-color: var(--primary);
        width: 100vw;
        overflow-x: hidden;
        text-align: center;
        z-index: 9999;
        transition: none;
    }

    .nav-menu.active {
        right: 0;
        background-color: rgba(16, 16, 16, .94);
        backdrop-filter: blur(4px);
    } 
    
    .nav-item {
        margin: 16px 0;
    }
}

@media (max-width: 475px) {
    .hamburger-bar {
        width: 1.5rem;
    }
}