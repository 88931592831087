#about  {
    max-width: 1200px;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto; 
}

.about-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.about-content-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;
    justify-content: center;    
}

#about h3 {
    font-size: 2rem;
}

.about-card {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 20px 0;    
    justify-content: space-between;
    align-items: flex-start;
}

.about-tags {
    background-color: var(--text);
    color: var(--inverse-text);
    padding: 5px 10px 5px 10px;
    margin: 10px 10px 0 0;
    border-radius: 20px;
    font-size: 1rem;
}

.about-tags-container {
    margin: 5px 10px 5px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.picture-container {
    display: flex;
    width: 60%;
    height: 720px;
    justify-content: center; 
    align-items: center; 
    min-width: 280px;
}

.about-text-container-mobile {
    display: none;
}

.about-text-container-mobile p {
    margin-top: 10px;
}

@media (max-width: 1125px) {
    .about-text-container {
        display: none;
    }

    .about-text-container-mobile {
        display: block;
        margin-bottom: 100px;
        padding: 5px;
    }

    .about-content-container, .picture-container {
        width: 100%;
    }

    .picture-container {
        height: 575px;
    }

}

@media (max-width: 650px) {
    .picture-container {
        height: 500px;
    }
}

@media (max-width: 550px) {
    .picture-container {
        height: 450px;
    }
}

@media (max-width: 500px) {
    .picture-container {
        height: 350px;
    }
}

@media (max-width: 450px) {
    .picture-container {
        height: 300px;
    }
}

@media (max-width: 350px) {
    .picture-container {
        height: 250px;
    }
    #about  {
        width: 100%;
        margin: 5px;
    }
}
