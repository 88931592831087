.contact-section {
    min-height: 80%;
    max-height: fit-content;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-card {
    background-color: var(--secondary-light);
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding: 60px 30px 30px 30px;
    margin-bottom: 30px;
}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
}

.message-container p {
    margin: 0;  
}

.contact-message p {
    max-width: 500px;
    font-size: 1.5rem;
}

.contact-dot {
    height: 60px;
    width: 60px;
    min-width: 60px;
    background-color: var(--secondary);
    left: 50%; 
    border-radius: 50%;
    border: 4px solid var(--text);
    display: flex;
    align-items: center;
    justify-content: center;
}

.seeking-title {
    font-weight: 600;
    padding-bottom: 2rem;
}

.seeking-container {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 2rem;
}

.seeking-container div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.seeking-container p {
    line-height: 1;
    font-size: 1.25rem;
    margin-left: 10px;
}

/* FORM */

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    align-items: center;
}

.email-note {
    font-weight: 500;
}

.email a {
    color: var(--text);
    text-decoration: none;
    font-weight: 600;
}

.form-container form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.input-container {
    margin-bottom: 25px;
    position: relative;
}

.error {
    position: absolute; 
    bottom: -20px; 
    left: 0;
    display: none; 
}

.error.show {
    display: block;
    font-size: .8rem;
    background-color: var(--action);
    color:rgba(16, 16, 16, 1);
    border-radius: 20px;
    padding: 0 10px 0 10px;
}

form, input, textarea {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    padding: 5px;
}

input, textarea {
    border: none;
}

textarea {
    max-width: 100%;
    min-width: 100%;
    height: 100px;
}

.submit-button {
    height: 72px;
    padding: 0 15px 0 15px;
    font-size: 1rem;
    font-weight: bold;
    color:  rgba(16, 16, 16, 1);
    width: 100%;
    background-color: var(--action);
    align-items: center;
    justify-content: center;
}

.submit-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.submit-button-container div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-button-container span {
    line-height: 1;
}

/* form submit modal */

.message-modal {
    background-color: var(--background);
    color: var(--text);
    border: 4px solid var(--text);
    padding: 20px;
}

.contact-close-button {
    background-color: var(--action);
    margin: 20px;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    display: block;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color:  rgba(16, 16, 16, 1);
}

.message-modal h2 {
    margin-bottom: 1rem;
}

.message-modal span {
    font-weight: 600;
}

@media (max-width: 900px) {
    .message-container, .form-container {
        width: 100%;
    }

    .message-container {
        padding-bottom: 30px;
    }

    .form-card {
        margin-bottom: 30px;
    }
}

@media (max-width: 450px) {
    .email-note {
        font-size: .75rem;
    }
    .contact-message p {
        font-size: 1rem;
    }
    .message-container {
        margin: 0;
        padding: 0;
    }
    .error.show {
    font-size: .6rem;
    }
}

@media (max-width: 415px) {
    .submit-button {
        width: 100%;
    }
    .form-card {
    padding: 20px;
    }
}

@media (max-width: 370px) {
    .message-modal {
        padding: 10px;
        font-size: 1rem;
        width: 100vw;
        height: auto;
    }

    .message-modal span {
        font-size: .8rem;
    }
}